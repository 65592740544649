"use client";

import { createContext, useContext, type ReactNode } from "react";
import {
  useFundPortfolio,
  type WalletToken,
} from "@/hooks/nav/useFundPortfolio";
import type { Protocol } from "@/data/interfaces/fund_state";

interface FundPortfolioContext {
  data: FundPortfolio | undefined;
  isFetching: boolean;
  isLoading: boolean;
  isFetchedAfterMount: boolean;
  isError: boolean;
}

interface FundPortfolio {
  protocols: Protocol[] | undefined;
  wallet: WalletToken[] | undefined;
}

const FundPortfolioContext = createContext<FundPortfolioContext | undefined>(
  undefined,
);

export const FundPortfolioProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data, isFetching, isFetchedAfterMount, isLoading, isError } =
    useFundPortfolio();

  return (
    <FundPortfolioContext.Provider
      value={{
        data,
        isFetching,
        isLoading,
        isFetchedAfterMount,
        isError,
      }}
    >
      {children}
    </FundPortfolioContext.Provider>
  );
};

export const useFundPortfolioContext = () => {
  const context = useContext(FundPortfolioContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};
