import { useConfigContext } from "@/context/configContext";
import { getLatestState } from "@/data/api/fund";
import { calculateScaledSharePrice } from "@/utils/math";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

interface UseLatestStateOptions {
  enableRefetch?: boolean;
  withExtended?: boolean;
  withNavPerChain?: boolean;
}

export const useLatestState = ({
  enableRefetch = false,
  withExtended = false,
  withNavPerChain = false,
}: UseLatestStateOptions = {}) => {
  const { fund } = useConfigContext();
  const { data, isError, isLoading, isFetchedAfterMount } = useQuery({
    queryKey: ["latestState"],
    queryFn: () => getLatestState(fund.name, withExtended, withNavPerChain),
    refetchInterval: enableRefetch ? 10000 : false,
    refetchOnWindowFocus: enableRefetch,
  });

  const state = useMemo(() => {
    if (!data) return undefined;

    const totalSupply = data.supply.totalSupply;
    const navUSD = data.nav.usd;

    return {
      nav: navUSD,
      supply: totalSupply,
      navPerChain: data.navPerChain,
      startTimestamp: data.startTimestamp,
      usdcBalance: data.usdcBalance,
      scaledSharePrice: calculateScaledSharePrice(totalSupply, navUSD),
    };
  }, [data]);

  return {
    data: state,
    isError,
    isLoading,
    isFetchedAfterMount,
  };
};
