import { useFundStateContext } from "@/context/fundStateContext";
import { formatNavToUSD } from "@/utils/formatters";
import { DoughnutChart } from "./PortfolioDoughnutChart";
import {
  AssetDistributionWrapper,
  AssetsDistributionLoader,
} from "../PortfolioAssetsDistribution";
import { useMemo } from "react";
import _ from "lodash";
import ErrorDisplay from "@/components/ErrorDisplay";

export function FundsByChainChart() {
  const { data, isLoading, isError, isFetchedAfterMount } =
    useFundStateContext();

  const navsPerChain = data?.navPerChain;

  const chartData = useMemo(() => {
    return navsPerChain
      ? _.map(navsPerChain, (value, key) => ({
          label: _.capitalize(key),
          value: Number(formatNavToUSD(value.usd, false)),
        }))
      : undefined;
  }, [navsPerChain]);

  return (
    <AssetDistributionWrapper title="Total funds by blockchain">
      {isLoading ? (
        <AssetsDistributionLoader />
      ) : isError || !chartData ? (
        <div className="p-20">
          <ErrorDisplay />
        </div>
      ) : (
        <DoughnutChart data={chartData} />
      )}
    </AssetDistributionWrapper>
  );
}
