import React from "react";
import _ from "lodash";
import ErrorDisplay from "@/components/ErrorDisplay";

export default function LoadingErrorChart({ isError }: { isError: boolean }) {
  return (
    <div className="relative h-96 w-full p-10 ">
      <div className="absolute inset-0 mt-4 mb-7 ml-24 mr-10 border-b-2 border-l-2 border-gray-600">
        {isError ? (
          <div className="mt-14 mr-14">
            <ErrorDisplay />
          </div>
        ) : (
          <div className="mr-14">
            {_.times(6, (index) => (
              <div
                key={index}
                className="absolute inset-x-0 border-t border-dashed border-gray-600 animate-pulse"
                style={{ top: `${index * (100 / 6)}%`, borderTopWidth: "1px" }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
