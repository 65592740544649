import { DECIMALS, INITIAL_SHARE_PRICE } from "@/constants/wallet";
import Big from "big.js";
import _ from "lodash";

export function multiplyBigIntWithNumber(
  bigintValue: bigint,
  multiplier: number,
): bigint {
  const bigintBig = Big(bigintValue.toString());
  const multiplierBig = Big(multiplier);

  const result = bigintBig.times(multiplierBig).toFixed(0);

  return BigInt(result);
}

export function calculateScaledSharePrice(shares: bigint, nav: bigint): number {
  if (shares === 0n) {
    return INITIAL_SHARE_PRICE;
  }
  const sharesBig = Big(shares.toString());
  const navBig = Big(nav.toString());

  const scaling = Big(10).pow(DECIMALS.SHARES - DECIMALS.NAV);
  const scaledNavBig = navBig.times(scaling);

  const sharePrice = scaledNavBig.div(sharesBig);

  return sharePrice.toNumber();
}
