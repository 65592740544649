"use client";

import ArrowUpRight from "@/components/Icons/ArrowUpRight";
import { useConfigContext } from "@/context/configContext";
import { openAddressBlockExplorer, shortenAddress } from "@/utils/addresses";

import { Button } from "@headlessui/react";
import type { Config } from "@wagmi/core";
import type { Address } from "viem";

export default function SafeAddresses() {
  const { fund, chainConfig } = useConfigContext();
  const safes = fund.safes;
  return (
    <div className="py-10 flex flex-row">
      {safes.map((safe) => (
        <SafeAddress
          key={safe.chain}
          safeAddress={safe.address}
          chainConfig={chainConfig}
        />
      ))}
    </div>
  );
}

function SafeAddress({
  safeAddress,
  chainConfig,
}: {
  safeAddress: Address;
  chainConfig: Config;
}) {
  return (
    <Button
      onClick={() => openAddressBlockExplorer(safeAddress, chainConfig)}
      className="bg-gray-900 rounded-[80px] py-2 gap-3 flex flex-row items-center mx-4 pl-6 pr-4"
    >
      <span className="font-mono font-bold text-base text-gray-100">
        {shortenAddress(safeAddress)}
      </span>
      <ArrowUpRight size={20} color="#fff" />
    </Button>
  );
}
