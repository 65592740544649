"use client";

import LabelValue from "../LabelValue";

import { formatToSharesUnits, formatToUSDCUnits } from "@/utils/formatters";
import { useAuthorizationContext } from "@/context/authorizationContext";
import { useReadSharesBalanceOf } from "@/wagmi.generated";
import { useConfigContext } from "@/context/configContext";

import { useMemo } from "react";
import { useFundStateContext } from "@/context/fundStateContext";
import {
  calculateNavUSDValueBasedOnShares,
  calculateUserTotalReturn,
} from "../../_helpers/fund_calculator";

interface TotalRedeemData {
  totalValue: string;
  totalShares: string;
}

interface TotalDepositData {
  totalValue: string;
  totalShares: string;
}

interface SubscriptionData {
  redeemData: TotalRedeemData | undefined;
  depositData: TotalDepositData | undefined;
  isDepositFetched: boolean;
}

export default function SubscriptionData({
  redeemData,
  depositData,
  isDepositFetched,
}: SubscriptionData) {
  const { address } = useAuthorizationContext();

  const totalDepositShares = depositData?.totalShares;
  const totalDepositValue = depositData?.totalValue;
  const totalRedeemValue = redeemData?.totalValue;
  const { fund } = useConfigContext();
  const { data: fundState } = useFundStateContext();

  const { data: sharesBalance, isFetchedAfterMount: isFetchedShares } =
    useReadSharesBalanceOf({
      args: [address],
      address: fund.shares.address,
      query: {
        enabled: !!fundState,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    });

  const userNavValue = useMemo(() => {
    if (sharesBalance !== undefined && fundState !== undefined) {
      return calculateNavUSDValueBasedOnShares(
        fundState.scaledSharePrice,
        sharesBalance,
      );
    }
    return undefined;
  }, [sharesBalance, fundState]);

  const getUserTotalReturn = useMemo(() => {
    if (
      sharesBalance !== undefined &&
      fundState !== undefined &&
      totalDepositValue !== undefined &&
      totalRedeemValue !== undefined
    ) {
      return calculateUserTotalReturn(
        fundState.scaledSharePrice,
        totalDepositValue,
        totalRedeemValue,
        sharesBalance,
      );
    }
    return undefined;
  }, [sharesBalance, fundState, totalDepositValue, totalRedeemValue]);

  // const userRealizedReturns = useMemo(() => {
  //   if (totalRedeemValue !== undefined && totalDepositValue !== undefined) {
  //     return calculateUserRealizedReturn(
  //       totalDepositValue,
  //       totalRedeemValue,
  //     );
  //   }
  //   return undefined;
  // }, [totalRedeemValue, totalDepositValue]);

  return (
    <div className="grid grid-cols-3 gap-4 w-full mt-10 pt-5 pb-10 border-b border-gray-400 px-5">
      <div className="col-span-1 px-2">
        <LabelValue
          isFetched={isDepositFetched}
          label="Subscribed amount"
          value={
            totalDepositValue !== undefined
              ? formatToUSDCUnits(BigInt(totalDepositValue))
              : undefined
          }
        />
      </div>

      <div className="col-span-1 px-2 space-y-10">
        <LabelValue
          isFetched={isDepositFetched}
          label="# shares"
          showSmallerValue={true}
          value={
            totalDepositShares !== undefined
              ? formatToSharesUnits(BigInt(totalDepositShares))
              : undefined
          }
        />
        <LabelValue
          isFetched={isFetchedShares || !fundState}
          label="NAV"
          showSmallerValue={true}
          value={userNavValue}
        />
      </div>

      <div className="col-span-1 px-2 space-y-10">
        {/* <LabelValue
          isFetched={isRedeemFetched && isDepositFetched}
          label="Realised return"
          showSmallerValue={true}
          value={userRealizedReturns}
        /> */}
        <LabelValue
          isFetched={(isFetchedShares || !fundState) && isDepositFetched}
          label="Total return"
          showSmallerValue={true}
          value={getUserTotalReturn}
        />
      </div>
    </div>
  );
}
