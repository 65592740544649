import React from "react";
import Warning from "./Icons/Warning";

export default function ErrorDisplay() {
  return (
    <div className="flex flex-col justify-center items-center space-y-4">
      <Warning />
      <div className="font-sans text-gray-900 text-lg">
        <p>Something went wrong.</p>
        <p>Please reload the page.</p>
      </div>
    </div>
  );
}
