import { useTotalUserRedeems } from "@/hooks/operations/useTotalUserRedeem";
import SectionTitle from "../SectionTitle";
import RedemptionData from "./RedemptionData";
import SubscriptionData from "./SubscriptionData";
import { useTotalUserDeposits } from "@/hooks/operations/useTotalUserDeposit";
import { useRef } from "react";

export default function UserPosition() {
  const userPositionRef = useRef(null);

  const { data: totalRedeemData, isFetchedAfterMount: isFetchedRedeems } =
    useTotalUserRedeems();

  const { data: totalDepositData, isFetchedAfterMount: isFetchedDeposits } =
    useTotalUserDeposits();

  return (
    <div id="user-position" ref={userPositionRef} className="py-10">
      <div className="px-5">
        <SectionTitle>Your position</SectionTitle>
      </div>
      <SubscriptionData
        redeemData={totalRedeemData}
        depositData={totalDepositData}
        isDepositFetched={isFetchedDeposits}
      />
      <RedemptionData data={totalRedeemData} isFetched={isFetchedRedeems} />
    </div>
  );
}
