import axios from "../axios";
import { parseWithSchema } from "../parser";
import {
  fundPortfolioSchema,
  fundTimeseriesSchema,
  latestStateSchema,
} from "../interfaces/fund_state";

export async function getFundStateTimeseries(
  fundId: string,
  from: string,
  to: string,
) {
  try {
    const response = await axios.get(`/fund/${fundId}/timeseries`, {
      params: { from, to },
    });
    return parseWithSchema(response.data, fundTimeseriesSchema);
  } catch (error) {
    console.error("Error fetching latest state:", error);
    throw new Error("Failed to fetch latest state");
  }
}

export async function getFundPortfolio(fundId: string) {
  try {
    const response = await axios.get(`/fund/${fundId}/portfolio`);
    return parseWithSchema(response.data, fundPortfolioSchema);
  } catch (error) {
    console.error("Error fetching fund portfolio:", error);
    throw new Error("Failed to fetch fund portfolio");
  }
}

export async function getLatestState(
  fundId: string,
  withExtended = false,
  withNavPerChain = true,
) {
  try {
    const response = await axios.get(`/fund/${fundId}`, {
      params: { withExtended, withNavPerChain },
    });
    return parseWithSchema(response.data, latestStateSchema);
  } catch (error) {
    console.error("Error fetching latest state:", error);
    throw new Error("Failed to fetch latest state");
  }
}
