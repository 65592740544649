import { INITIAL_SHARE_PRICE } from "@/constants/wallet";
import LabelValue from "../LabelValue";
import { useFundStateContext } from "@/context/fundStateContext";
import {
  calculateAnnualizedReturn,
  calculateTotalReturnPercentage,
} from "../../_helpers/fund_calculator";

export default function FundPerformanceData() {
  const {
    data: fundState,
    isFetchedAfterMount: isFundStateFetchedAfterMount,
    isError: isFundStateError,
  } = useFundStateContext();

  return (
    <div className="grid grid-cols-3 md:grid-cols-5 space-x-4 w-full">
      <LabelValue
        label="Total return"
        isFetched={isFundStateFetchedAfterMount}
        value={
          fundState !== undefined
            ? calculateTotalReturnPercentage(
                fundState.scaledSharePrice,
                INITIAL_SHARE_PRICE,
              )
            : undefined
        }
      />
      <LabelValue
        label="Annualised return"
        isFetched={isFundStateFetchedAfterMount}
        value={
          fundState?.startTimestamp
            ? calculateAnnualizedReturn(
                fundState.scaledSharePrice,
                INITIAL_SHARE_PRICE,
                fundState.startTimestamp,
              )
            : undefined
        }
      />
      <LabelValue label="Alpha" value="-" />
      <LabelValue label="Beta" value="-" />
      <LabelValue label="Sharpe ratio" value="-" />
    </div>
  );
}
