"use client";

import React from "react";
import FundProperties from "./_components/fund_properties/FundProperties";
import UserPosition from "./_components/user_position/UserPosition";
import FundPerformance from "./_components/fund_performance/FundPerformance";
import { FundStateProvider } from "@/context/fundStateContext";
import { useAuthorizationContext } from "@/context/authorizationContext";
import Portfolio from "./_components/portfolio/Portfolio";

export default function ReportPage() {
  const { isLoggedIn } = useAuthorizationContext();

  return (
    <FundStateProvider>
      <FundProperties />
      <FundPerformance />
      <Portfolio />
      {isLoggedIn && <UserPosition />}
    </FundStateProvider>
  );
}
