import { useConfigContext } from "@/context/configContext";
import { getFundPortfolio } from "@/data/api/fund";
import type { TokenList } from "@/data/interfaces/fund_state";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import _ from "lodash";

export type WalletToken = {
  symbol: string;
  usdPrice: number;
  amount: number;
  totalUsdValue: number;
  share?: number;
};

export const useFundPortfolio = () => {
  const { fund } = useConfigContext();
  const { data, error, isFetching, isFetchedAfterMount, isLoading, isError } =
    useQuery({
      queryKey: ["fundPortfolio", fund.name],
      queryFn: () => getFundPortfolio(fund.name),
      refetchOnWindowFocus: false,
    });

  const wallet = useMemo(() => walletTokens(data?.tokens), [data?.tokens]);

  return {
    data: { protocols: data?.protocols, wallet },
    isError,
    isLoading,
    error,
    isFetching,
    isFetchedAfterMount,
  };
};

function walletTokens(
  tokens: TokenList | undefined,
): WalletToken[] | undefined {
  if (!tokens) return undefined;

  const validTokens = tokens.filter((token) => token.price);

  const groupedTokens = _.groupBy(validTokens, "symbol");

  const aggregatedTokens = _.map(groupedTokens, (group, symbol) => {
    const usdPrice = group[0].price || 0;
    const amount = _.sumBy(group, "amount");
    const totalUsdValue = _.sumBy(group, (token) => token.price * token.amount);

    return {
      symbol,
      usdPrice,
      amount,
      totalUsdValue,
    };
  });

  const walletUsdValue = _.sumBy(aggregatedTokens, "totalUsdValue");

  return aggregatedTokens.map((token) => ({
    ...token,
    share: walletUsdValue ? token.totalUsdValue / walletUsdValue : undefined,
  }));
}
