import type { WalletToken } from "@/hooks/nav/useFundPortfolio";
import {
  formatNumber,
  formatToPercentage,
  formatToUSD,
} from "@/utils/formatters";
import { createColumnHelper } from "@tanstack/react-table";

export const TABLE_PAGE_SIZE = 5;

const columnHelper = createColumnHelper<WalletToken>();

export const columns = [
  columnHelper.accessor("symbol", {
    header: "Token",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("usdPrice", {
    header: "Price",
    cell: (info) => formatToUSD(info.getValue()),
  }),
  columnHelper.accessor("amount", {
    header: () => <div className="flex justify-end">Amount</div>,
    cell: (info) => {
      const totalUsdValue = info.row.original.totalUsdValue;
      return (
        <div className="flex-col flex items-end">
          <span>{formatNumber(info.getValue())}</span>
          <span className="text-sm font-normal">
            {formatToUSD(totalUsdValue)}
          </span>
        </div>
      );
    },
  }),
  columnHelper.accessor("share", {
    header: () => <div className="flex justify-end">Share</div>,
    cell: (info) => (
      <div className="flex justify-end">
        {formatToPercentage(Number(info.getValue()))}
      </div>
    ),
  }),
];
