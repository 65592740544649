import { DateRangeType } from "@/constants/date";
import { subDays, subMonths, subYears, format, subWeeks } from "date-fns";

export function formatUTC(timestamp: bigint): string {
  const date = new Date(Number(timestamp) * 1000);
  return format(date, "dd/MM/yyyy HH:mm:ss");
}

export function getDateRangeForTab(dateRange: DateRangeType): {
  startDate: string;
  endDate: string;
} {
  const now = new Date();
  const endDate = now.toISOString();

  let startDate: string;

  switch (dateRange) {
    case DateRangeType.Day:
      startDate = subDays(now, 1).toISOString();
      break;
    case DateRangeType.Week:
      startDate = subWeeks(now, 1).toISOString();
      break;
    case DateRangeType.Month:
      startDate = subMonths(now, 1).toISOString();
      break;
    case DateRangeType.Year:
      startDate = subYears(now, 1).toISOString();
      break;
    case DateRangeType.All:
      startDate = new Date(0).toISOString();
      break;
    default:
      startDate = endDate;
  }

  return { startDate: startDate.split("T")[0], endDate: endDate.split("T")[0] };
}
