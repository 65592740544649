import React, { useMemo, useState } from "react";
import { Tabs } from "@/components";
import { useHistoricalNav } from "@/hooks/nav/useHistoricalNav";
import LineChart from "./LineChart";
import { dateRanges, DateRangeType } from "@/constants/date";
import { getDateRangeForTab } from "@/utils/date_time";
import LoadingErrorChart from "./LoadingErrorChart";

function NavVariationChart() {
  const [currentTab, setCurrentTab] = useState<DateRangeType>(
    DateRangeType.All,
  );

  const { startDate, endDate } = useMemo(() => {
    return getDateRangeForTab(currentTab);
  }, [currentTab]);

  const { data: historicalNav, isError } = useHistoricalNav({
    startDate,
    endDate,
    dateRangeFilter: currentTab,
  });

  return (
    <div className="pt-5 space-y-10 pr-10">
      <div className="flex justify-between items-center">
        <span className="font-mono text-gray-900 font-bold text-2xl">NAV</span>

        <Tabs
          tabs={dateRanges}
          selectedIndex={dateRanges.indexOf(currentTab)}
          setCurrentTab={(tab) => {
            setCurrentTab(dateRanges[tab]);
          }}
        />
      </div>

      {historicalNav === undefined ? (
        <LoadingErrorChart isError={isError} />
      ) : (
        <div className="h-96 w-full">
          <LineChart data={historicalNav} dateRange={currentTab} label="NAV" />
        </div>
      )}
    </div>
  );
}

export default NavVariationChart;
