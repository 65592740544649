import { useFundPortfolioContext } from "@/context/fundPortfolioContext";
import WalletTable from "./tables/WalletTable";

export default function PortfolioWallet() {
  const { data, isLoading, isError } = useFundPortfolioContext();
  return (
    <div className="py-10 border-t border-gray-400 px-5 space-y-10">
      <span className="font-mono text-2xl font-bold">Wallet</span>
      <WalletTable
        data={data?.wallet}
        isLoading={isLoading}
        isError={isError}
      />
    </div>
  );
}
