import axios from "../axios";
import { parseWithSchema } from "../parser";
import {
  OperationRequestState,
  type OperationRequestType,
  operationResponseSchema,
} from "../interfaces/operations";

interface OperationsParams {
  fundName: string;
  operationType: OperationRequestType;
  operationState?: OperationRequestState;
  limit?: number;
  page?: number;
}

export async function getInvestorOperations({
  fundName,
  operationType,
  operationState = OperationRequestState.All,
  limit,
  page,
}: OperationsParams) {
  try {
    const res = await axios.get(`/fund/${fundName}/investor/operations`, {
      params: { type: operationType, state: operationState, page, limit },
    });
    return parseWithSchema(res.data, operationResponseSchema);
  } catch (error) {
    console.error("Error getting operations:", error);
    throw error;
  }
}

export async function getOperations({
  fundName,
  operationType,
  operationState = OperationRequestState.All,
  limit,
  page,
}: OperationsParams) {
  try {
    const res = await axios.get(`/fund/${fundName}/operations`, {
      params: { type: operationType, state: operationState, page, limit },
    });
    return parseWithSchema(res.data, operationResponseSchema);
  } catch (error) {
    console.error("Error getting operations:", error);
    throw error;
  }
}
