"use client";

import LabelValue from "../LabelValue";
import { SYMBOLS } from "@/constants/wallet";
import { formatToSharesUnits, formatToUSD } from "@/utils/formatters";
import ArrowUpRight from "@/components/Icons/ArrowUpRight";
import { openAddressBlockExplorer } from "@/utils/addresses";
import { useConfigContext } from "@/context/configContext";
import { useFundStateContext } from "@/context/fundStateContext";
import { calculateCapitalUtilization } from "../../_helpers/fund_calculator";

export default function FundPropertiesData() {
  const { fund, chainConfig } = useConfigContext();

  const { data, isFetchedAfterMount, isError } = useFundStateContext();

  return (
    <div className="flex gap-20 px-5">
      <LabelValue label="Denomination Asset" value={SYMBOLS.USDC} />
      <LabelValue
        isFetched={isFetchedAfterMount}
        label="Capital Utilization"
        value={
          data?.usdcBalance
            ? calculateCapitalUtilization(data.usdcBalance, data.nav)
            : undefined
        }
      />
      <LabelValue
        isFetched={isFetchedAfterMount}
        label="Share Supply"
        icon={
          <ArrowUpRight
            width={24}
            height={24}
            onClick={() =>
              openAddressBlockExplorer(fund.shares.address, chainConfig)
            }
            className="cursor-pointer"
          />
        }
        value={data ? formatToSharesUnits(data.supply) : undefined}
      />
      <LabelValue
        isFetched={isFetchedAfterMount}
        label="Share Price"
        value={data ? formatToUSD(data.scaledSharePrice) : undefined}
      />
    </div>
  );
}
