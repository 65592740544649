import * as React from "react";
import type { SVGProps } from "react";
const KarpatkeyLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116}
    height={116}
    fill="none"
    className="flex-shrink-0"
    {...props}
  >
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={116}
        height={116}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="M58 116c32.032 0 58-25.968 58-58S90.032 0 58 0 0 25.968 0 58s25.968 58 58 58Z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#D9D9D9"
          d="M58 116c32.032 0 58-25.968 58-58S90.032 0 58 0 0 25.968 0 58s25.968 58 58 58Z"
        />
        <path
          fill="#232323"
          d="M58 116C26.01 116 0 89.99 0 58S26.01 0 58 0s58 26.01 58 58-26.01 58-58 58ZM58 2.287C27.285 2.287 2.287 27.285 2.287 58c0 30.716 24.998 55.713 55.713 55.713 30.716 0 55.713-24.997 55.713-55.713 0-30.715-24.997-55.713-55.713-55.713Z"
        />
        <path
          fill="#232323"
          d="M105.25 24.344C95.97 13.56 82.278 6.503 66.398 6.503c-36.5-.098-61.758 37.512-47.642 71.233 7.646 18.92 27.22 31.99 47.642 31.762 14.704.163 28.95-6.634 38.623-17.515C111.948 82.442 116 70.711 116 58c0-12.71-3.987-24.18-10.75-33.656Zm-38.852 82.441c-19.312.164-37.774-12.286-44.995-30.16C8.169 44.702 31.826 9.248 66.398 9.248c34.571-.098 58.49 35.52 45.093 67.444-7.254 17.906-25.782 30.323-45.093 30.127v-.033Z"
        />
        <path
          fill="#232323"
          d="M113.615 74.077c-6.209 15.423-22.187 26.075-38.82 25.846-16.566.098-32.381-10.62-38.492-25.977-11.338-27.35 8.92-57.673 38.493-57.771 18.527-.098 33.493 11.763 39.374 27.382a57.06 57.06 0 0 0-7.483-17.057C98.714 18.3 87.572 13.038 74.796 13.038c-31.958-.164-54.08 32.774-41.695 62.248 6.731 16.501 23.886 27.905 41.695 27.644 12.057.098 23.722-5.13 32.055-13.659a58.277 58.277 0 0 0 7.123-16.077c-.131.294-.229.621-.359.915v-.032Z"
        />
        <path
          fill="#232323"
          d="M108.615 86.363a57.837 57.837 0 0 0 4.052-8.92c-6.437 9.606-17.776 15.847-29.441 15.651-13.855.066-26.958-8.986-32.023-21.827-9.378-22.808 7.385-48.001 32.023-48.197 12.744-.131 23.494 6.535 29.67 16.142a59.64 59.64 0 0 0-4.444-9.869c-6.797-6.077-15.652-9.802-25.226-9.77-27.415-.229-46.4 27.97-35.748 53.262 5.817 14.084 20.52 23.756 35.748 23.462 9.64.032 18.593-3.758 25.389-9.934Z"
        />
        <path
          fill="#232323"
          d="M112.765 77.148c-5.293 5.751-12.874 9.313-21.141 9.15-11.143 0-21.566-7.418-25.553-17.743-7.417-18.168 5.882-38.395 25.553-38.656 8.332-.196 15.978 3.463 21.272 9.313a60.106 60.106 0 0 0-2.843-6.83c-5.294-3.92-11.763-6.24-18.43-6.24-22.84-.328-38.786 23.134-29.8 44.21 4.869 11.698 17.188 19.54 29.8 19.279 7.091-.033 13.463-2.451 18.593-6.34a52.79 52.79 0 0 0 2.549-6.143Z"
        />
        <path
          fill="#232323"
          d="M100.021 79.566c-8.43-.098-16.174-5.882-19.082-13.658-6.732-17.188 9.933-34.278 27.317-27.742 1.928.719 3.692 1.699 5.261 2.908a55.743 55.743 0 0 0-1.83-5.098c-6.241-3.529-13.986-4.444-21.403-1.535-15.031 5.75-21.076 24.67-11.6 37.838 4.803 6.797 13.07 10.849 21.37 10.653 4.248-.033 8.3-1.242 11.796-3.268a53.391 53.391 0 0 0 1.569-4.346c-3.823 2.843-8.561 4.444-13.365 4.28l-.033-.032Z"
        />
        <path
          fill="#232323"
          d="M108.419 72.868c-5.751-.164-10.783-4.346-12.613-9.673-4.28-11.567 6.47-22.84 18.266-18.854.131.033.229.098.36.131a80.864 80.864 0 0 0-1.177-4.248c-1.503-.49-3.137-.784-4.803-.882-10.49-.523-19.475 8.104-19.475 18.625-.13 10.555 9.215 18.593 19.475 18.168 1.699-.032 3.366-.36 4.967-.882.359-1.111.653-2.222.947-3.366a14.52 14.52 0 0 1-5.914.948l-.033.033Z"
        />
        <path
          fill="#232323"
          d="M108.713 54.667c1.373-2.778 4.052-4.248 6.764-4.346a54.504 54.504 0 0 0-.653-3.921c-5.131 1.372-8.921 6.34-8.66 11.6.099 5.097 3.824 9.868 8.758 11.077.196-.948.326-1.862.49-2.843-5.588-.36-9.346-6.208-6.699-11.6v.033Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h116v116H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default KarpatkeyLogo;
