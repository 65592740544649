import { useConfigContext } from "@/context/configContext";
import { getInvestorOperations } from "@/data/api/operations";
import {
  OperationRequestState,
  OperationRequestType,
} from "@/data/interfaces/operations";
import { useQuery } from "@tanstack/react-query";
import Big from "big.js";

export const useTotalUserDeposits = () => {
  const { fund } = useConfigContext();
  const { data, isLoading, isFetchedAfterMount } = useQuery({
    queryKey: ["totalDeposits"],
    queryFn: async () => {
      return await getInvestorOperations({
        fundName: fund.name,
        operationType: OperationRequestType.Deposit,
        operationState: OperationRequestState.Confirmed,
      });
    },
    refetchOnWindowFocus: false,
    select: (data) => {
      const totalValue = data.data
        .reduce((sum, deposit) => {
          return sum
            .plus(Big(deposit.assets.toString()))
            .plus(Big(deposit.priceImpactMargin.toString()));
        }, Big(0))
        .toFixed(0);

      const totalShares = data.data
        .reduce((sum, deposit) => {
          return sum.plus(Big(deposit.shares.toString()));
        }, Big(0))
        .toFixed(0);

      return {
        totalValue: totalValue,
        totalShares: totalShares,
      };
    },
  });

  return { data, isLoading, isFetchedAfterMount };
};
