import { Button } from "@headlessui/react";

interface ShowMorePaginationProps {
  initialItems: number;
  currItems: number;
  totalItems: number;
  setSize: (size: number) => void;
}

export default function ShowMorePagination({
  initialItems,
  currItems,
  totalItems,
  setSize,
}: ShowMorePaginationProps) {
  const label = `${currItems} of ${totalItems}`;
  const isShowingAll = currItems === totalItems;

  return (
    <div className="flex justify-center items-center gap-2 font-sans text-base py-3">
      {label}
      <Button
        onClick={() => setSize(isShowingAll ? initialItems : totalItems)}
        className="font-bold underline"
      >
        {isShowingAll ? "Show less" : "View all"}
      </Button>
    </div>
  );
}
