import { useFundPortfolioContext } from "@/context/fundPortfolioContext";
import { useMemo } from "react";
import {
  AssetDistributionWrapper,
  AssetsDistributionLoader,
} from "../PortfolioAssetsDistribution";
import { DoughnutChart } from "./PortfolioDoughnutChart";
import { orderBy, sumBy } from "lodash";
import ErrorDisplay from "@/components/ErrorDisplay";

export function FundsByAssetChart() {
  const { data, isLoading, isError } = useFundPortfolioContext();

  const chartData = useMemo(() => {
    const sortedData = orderBy(data?.wallet, ["totalUsdValue"], ["desc"]);

    const topTokens = sortedData.slice(0, 5);

    const othersValue = sumBy(sortedData.slice(5), "totalUsdValue");

    const finalData = topTokens.map((token) => {
      return { label: token.symbol, value: token.totalUsdValue };
    });

    return othersValue > 0
      ? [...finalData, { label: "Others", value: othersValue }]
      : finalData;
  }, [data]);

  return (
    <AssetDistributionWrapper title="Total funds by asset">
      {isLoading ? (
        <AssetsDistributionLoader />
      ) : isError ? (
        <div className="py-20">
          <ErrorDisplay />
        </div>
      ) : (
        chartData.length > 0 && <DoughnutChart data={chartData} />
      )}
    </AssetDistributionWrapper>
  );
}
