import SectionTitle from "../SectionTitle";
import FundPerformanceData from "./FundPerformanceData";
import FundPerformanceCharts from "./FundPerformanceCharts";
import React from "react";
import { useRef } from "react";

function FundPerformance() {
  const fundPerformanceRef = useRef(null);

  return (
    <div
      id="fund-performance"
      ref={fundPerformanceRef}
      className="py-10 border-b border-b-gray-800 scroll-mt-28"
    >
      <div className="px-5 space-y-10">
        <SectionTitle>Fund performance</SectionTitle>
        <FundPerformanceData />
        <FundPerformanceCharts />
      </div>
    </div>
  );
}

export default FundPerformance;
