import ErrorDisplay from "@/components/ErrorDisplay";
import ShowMorePagination from "@/components/ShowMorePagination";
import type { WalletToken } from "@/hooks/nav/useFundPortfolio";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import { columns, TABLE_PAGE_SIZE } from "./WalletTableData";
import EmptyState from "@/components/EmptyState";

interface WalletTableProps {
  data: WalletToken[] | undefined;
  isLoading: boolean;
  isError: boolean;
}

export default function WalletTable({
  data,
  isLoading = true,
  isError = false,
}: WalletTableProps) {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: TABLE_PAGE_SIZE,
  });

  const tableData = useMemo(
    () => (isLoading ? Array(TABLE_PAGE_SIZE).fill({}) : data),
    [isLoading, data],
  );

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => (
              <div className="animate-pulse rounded h-3 w-20 bg-gray-200" />
            ),
          }))
        : columns,
    [isLoading],
  );

  const table = useReactTable({
    columns: tableColumns,
    data: tableData ? tableData : [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    initialState: {
      sorting: [
        {
          id: "share",
          desc: true,
        },
      ],
    },
  });

  return (
    <div>
      <table className="w-full table-fixed font-mono font-bold text-base">
        <thead className="border-b border-gray-400">
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="text-left py-4 px-3">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody className="font-mono text-sm">
          {isError ? (
            <tr>
              <td colSpan={columns.length} className="py-40">
                <ErrorDisplay />
              </td>
            </tr>
          ) : !isLoading && data?.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="py-40">
                <EmptyState />
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className="border-b border-gray-400">
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="text-left px-3 py-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {data && data.length > TABLE_PAGE_SIZE && (
        <ShowMorePagination
          initialItems={TABLE_PAGE_SIZE}
          currItems={table.getState().pagination.pageSize}
          totalItems={data.length}
          setSize={(size) => {
            table.setPageSize(size);
          }}
        />
      )}
    </div>
  );
}
