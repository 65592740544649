import { DECIMALS, SYMBOLS } from "@/constants/wallet";
import { formatUnits, parseUnits } from "viem";

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
});

export const percentageFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const usdCompactFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  notation: "compact",
  compactDisplay: "short",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const sharesFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
});

export const usdcFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatUSDCtoUSD(value: bigint, includeTicker = true): string {
  const formattedAmount = formatUnits(value, DECIMALS.USDC);
  if (!includeTicker) return formattedAmount.toString();

  const numberValue = Number.parseFloat(formattedAmount);
  return usdFormatter.format(numberValue);
}

export function formatNavToUSD(value: bigint, includeTicker = true): string {
  const formattedAmount = formatUnits(value, DECIMALS.NAV);
  if (!includeTicker) return formattedAmount.toString();

  const numberValue = Number.parseFloat(formattedAmount);
  return usdFormatter.format(numberValue);
}
export function formatToUSDCUnits(
  amount: bigint,
  includeTicker = true,
): string {
  const formattedAmount = formatUnits(amount, DECIMALS.USDC);
  if (!includeTicker) return formattedAmount;
  const formattedString = usdcFormatter.format(
    Number.parseFloat(formattedAmount),
  );
  return `${formattedString} ${SYMBOLS.USDC}`;
}

export function parseToSharesUnits(amount: string): bigint {
  return parseUnits(amount, DECIMALS.SHARES);
}

export function formatToSharesUnits(
  amount: bigint,
  includeTicker = true,
): string {
  const formattedAmount = formatUnits(amount, DECIMALS.SHARES);

  if (!includeTicker) return formattedAmount;

  const sharesValue = sharesFormatter.format(
    Number.parseFloat(formattedAmount),
  );

  return `${sharesValue} ${SYMBOLS.SHARES}`;
}

export function formatNumber(value: number): string {
  return numberFormatter.format(value);
}

export const formatToCompactUSD = (value: number): string => {
  return usdCompactFormatter.format(value);
};

export function parseToUSDCUnits(amount: string): bigint {
  return parseUnits(amount, DECIMALS.USDC);
}

export const formatToPercentage = (value: number): string => {
  return percentageFormatter.format(value);
};

export function formatToUSD(value: number): string {
  const displayValue = value < 0 && value > -0.005 ? 0 : value;

  return usdFormatter.format(displayValue);
}
