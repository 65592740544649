"use client";

import { createContext, useContext, type ReactNode, useMemo } from "react";
import { useLatestState } from "@/hooks/nav/useLatestState";
import type { NavPerChain } from "@/data/interfaces/fund_state";

interface FundStateContextType {
  data: FundState | undefined;
  isLoading: boolean;
  isFetchedAfterMount: boolean;
  isError: boolean;
}

export interface FundState {
  nav: bigint;
  supply: bigint;
  navPerChain: NavPerChain;
  startTimestamp: bigint | undefined;
  usdcBalance: bigint | undefined;
  scaledSharePrice: number;
}

const FundStateContext = createContext<FundStateContextType | undefined>(
  undefined,
);

export const FundStateProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading, isFetchedAfterMount, isError } = useLatestState({
    withExtended: true,
    withNavPerChain: true,
  });

  return (
    <FundStateContext.Provider
      value={{
        data,
        isLoading,
        isFetchedAfterMount,
        isError,
      }}
    >
      {children}
    </FundStateContext.Provider>
  );
};

export const useFundStateContext = () => {
  const context = useContext(FundStateContext);
  if (!context) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};
