import { PageTitle } from "@/components";
import KarpatkeyLogo from "@/components/Icons/KarpatkeyLogo";

export default function FundPropertiesHeader() {
  return (
    <div className="px-5 gap-5 flex flex-row items-center flex-nowrap">
      <KarpatkeyLogo />
      <div className="flex-col flex space-y-5">
        <PageTitle>USD Stable Yield Fund</PageTitle>
        <p className="font-semibold text-3xl font-mono">Report</p>
      </div>
    </div>
  );
}
