"use client";

import LabelValue from "../LabelValue";
import { formatToSharesUnits, formatToUSDCUnits } from "@/utils/formatters";

interface TotalRedeemData {
  totalValue: string;
  totalShares: string;
}

interface RedemptionDataProps {
  data: TotalRedeemData | undefined;
  isFetched: boolean;
}

export default function RedemptionData({
  data,
  isFetched,
}: RedemptionDataProps) {
  const totalValue = data?.totalValue;
  const totalShares = data?.totalShares;

  return (
    <div className="grid grid-cols-3 gap-4 w-full h-[256px] pt-10 px-5 pb-10">
      <div className="col-span-1 px-2">
        <LabelValue
          isFetched={isFetched}
          label="Redeemed amount"
          value={
            totalValue !== undefined
              ? formatToUSDCUnits(BigInt(totalValue))
              : undefined
          }
        />
      </div>
      <div className="col-span-1 px-2">
        <LabelValue
          isFetched={isFetched}
          label="# shares"
          showSmallerValue={true}
          value={
            totalShares !== undefined
              ? formatToSharesUnits(BigInt(totalShares))
              : undefined
          }
        />
      </div>
    </div>
  );
}
