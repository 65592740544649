import classNames from "classnames";
import type React from "react";

interface LabelValueProps {
  isFetched?: boolean;
  label: string;
  value?: string;
  icon?: React.ReactNode;
  showSmallerValue?: boolean;
}

export default function LabelValue({
  isFetched = true,
  label,
  value,
  icon,
  showSmallerValue = false,
}: LabelValueProps) {
  return (
    <div className="space-y-3 text-gray-900 font-semibold spacing-y-3">
      <p className="font-sans text-lg">{label}</p>
      {valueBasedOnFetched(isFetched, value, icon, showSmallerValue)}
    </div>
  );
}

function valueBasedOnFetched(
  isFetched: boolean,
  value: string | undefined,
  icon: React.ReactNode | undefined,
  showSmallerValue: boolean,
) {
  if (!isFetched) {
    return <div className="animate-pulse rounded h-8 w-20 bg-gray-200" />;
  }
  return (
    <div className="flex items-center space-x-3">
      <p
        className={classNames("font-mono text-3xl", {
          "text-3xl": !showSmallerValue,
          "text-xl": showSmallerValue,
        })}
      >
        {value !== undefined ? value : "-"}
      </p>
      {value !== undefined && icon && <span>{icon}</span>}
    </div>
  );
}
