import FundPropertiesData from "./FundPropertiesData";
import FundPropertiesHeader from "./FundPropertiesHeader";
import SafeAddresses from "./SafeAddresses";
import React from "react";
import { useRef } from "react";

function FundProperties() {
  const fundPropertiesRef = useRef(null);

  return (
    <div
      id="fund-properties"
      ref={fundPropertiesRef}
      className="py-10 border-b border-b-gray-800 scroll-mt-28"
    >
      <FundPropertiesHeader />
      <SafeAddresses />
      <FundPropertiesData />
    </div>
  );
}

export default FundProperties;
