import { FundsByAssetChart } from "./charts/FundsByAssetChart";
import { FundsByChainChart } from "./charts/FundsByChainChart";
import { FundsByProtocolChart } from "./charts/FundsByProtocolChart";

export default function PortfolioAssetsDistribution() {
  return (
    <div className="px-5 grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
      <FundsByChainChart />
      <FundsByProtocolChart />
      <FundsByAssetChart />
    </div>
  );
}

export function AssetDistributionWrapper({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <div className="border rounded p-5 min-h-[460px] max-h-[600px] border-gray-400">
      <p className="text-gray-900 text-lg font-bold font-sans pb-10">{title}</p>
      <div className="min-h-[460px] max-h-[600px]">{children}</div>
    </div>
  );
}

export function AssetsDistributionLoader() {
  return (
    <div className="py-5">
      <div className="w-full aspect-[1] bg-gray-200 animate-pulse rounded-full" />
    </div>
  );
}
