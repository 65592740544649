import { CategoryScale, Chart } from "chart.js/auto";
import NavVariationChart from "./charts/NavVariationChart";
import React from "react";
import SharePriceVariationChart from "./charts/SharePriceVariationChart";

Chart.register(CategoryScale);

function FundPerformanceCharts() {
  return (
    <>
      <NavVariationChart />
      <SharePriceVariationChart />
    </>
  );
}

export default FundPerformanceCharts;
